body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  color: var(--sase-blue);
}

section {
  margin: 25px auto;
  padding: 0 15px;
  max-width: 1100px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn {
  padding: 5px 25px;
  margin-top: 10px;
}

.btn:hover {
  background-color: var(--sase-green);
}

@media (max-width: 768px) {
  .btn {
    display: block;
  }
}

:root {
  --light-grey: #E8EAEC;
  --raven-grey: #676763;
  --sase-blue: #0668B3;
  --sase-green: #7DC242; 
}

a { 
  color: inherit; 
} 

a:link {
  text-decoration: none;
}