.footer {
    height: 200px;
    background-color:var(--light-grey);
    text-align: center;
    padding-top:20px;
    font-size: 12px;

}
.footer .logo {
    height: 30px;
    margin: 10px;
}