.about .container {
  margin: 0 auto 50px;
}

.bio-container {
  margin: auto;
}

.bio-container h3 {
  color: var(--sase-blue);
}

hr {
  width: 80%;
  border-top: 2px solid --raven-grey;
  margin-left: auto;
  margin-right: auto;
}

.past-member-container .logo {
  height: 30px;
  margin: 10px;
}

.past-member-container p {
  margin: auto 0;
}

.past-member-container .name {
  font-size: 18px;
  font-weight: bold;
  color: var(--sase-blue);
}

.current-board-accordian {
  margin-top: 20px;
}

.toggle-text {
  font-size: 20px;
  font-weight: bold;
}

.bio-container .logo {
  height: 30px;
  margin: 10px;
  margin-left: 15px;
}

.bio-container h3 {
  margin: auto 0;
}
