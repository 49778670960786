.register h3 {
  margin-top: 50px;
  color: var(--sase-blue);
}

.register .qrcode {
  display: block;
  margin-top: 50px;
}

.register .qrcode-image {
  box-shadow: 5px 5px 5px var(--light-grey), 0px 0px 10px var(--light-grey);
}

.register .caption {
  margin-top: 10px;
}