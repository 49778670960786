.home .carousel img {
  top: 0;
  left: 0;
  min-width: 100%;
  height: 700px;
  max-width: none;
  object-fit: cover;
}

@media (max-width: 768px) {
  .home h1 {
    font-size: 25px;
  }

  .home .carousel img {
    height: 350px;
  }
}

.home ul {
  list-style-type: circle;
}

.home h4 {
  color: var(--sase-blue);
}

.home body {
  color: var(--raven-grey);
}

.underline {
  text-decoration: underline;
}

.home .logo {
  height: 20px;
  margin: 10px;
}

.home .eventName {
  margin: 10px 0 0;
}

.home .row {
  margin: unset;
}

.home .spinner {
  height: 100px;
  width: 100px;
  border-width: 10px;
  color: var(--sase-blue);
  display: flex;
  margin: 200px auto 0;
}

.home h2 {
  font-size: 25px;
}

.home h3 {
  font-size: 18px;
}

.home .upcoming-events p {
  margin-bottom: 0;
}
