#map {
  width: 100%;
  height: 500px;
  margin-bottom: 10px;
}

.underline {
  text-decoration: underline;
}

.marker-past {
  background-image: url(./SASEPro_Logo.png);
  font-family: Arial, Helvetica, sans-serif;
  background-size: cover;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.marker-upcoming {
  background-image: url(./SASEPro_Logo2.png);
  background-size: cover;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: "Open Sans", sans-serif;
}

.events .container {
  margin: 0 auto 50px;
}
.events {
  margin-top: 120px;
}

.events .carousel img {
  top: 0;
  left: 0;
  height: 500px;
  max-width: max;
  object-fit: cover;
}

.events .carouselcol {
  text-align: center;
}

.events .banner {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: var(--sase-blue);
  color: white;
  justify-content: center;
  margin-bottom: 10px;
}

.events .logo {
  height: 30px;
  margin: 10px;
}

.events .eventName {
  margin: auto 0;
  cursor: pointer;
}

.events .row {
  justify-content: center;
}

.events .spinner {
  height: 100px;
  width: 100px;
  border-width: 10px;
  color: var(--sase-blue);
  display: flex;
  margin: auto auto 20px;
}

.events .legendLogo {
  margin: 8px;
}

.events .legendText {
  margin: auto 0 auto;
}

.events .legend {
  outline: 2px solid black;
  margin: auto;
  width: 100%;
}

.events .col-sm-6 {
  display: flex;
  flex-direction: row;
}

.events .past-events-list {
  text-align: center;
  margin-bottom: 50px;
}

.events .past-events-list .col-sm-4 {
  padding-bottom: 30px;
}
