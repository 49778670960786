.logo {
    max-width: 20rem;
}

@media (max-width: 768px) {
    .logo {
        max-width: 10rem;
    }
}

.nav-header {
    margin-bottom: 110px;
}

.navbar {
    box-shadow: 0 2px 2px var(--light-grey);
}

